import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
// import NotFound from 'src/NotFound';
import Layout from 'src/Layout';
import {
  RenderingType,
  SitecoreContext,
  ComponentPropsContext,
  handleEditorFastRefresh,
  EditingComponentPlaceholder,
  StaticPath,
  RouteData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
// different componentFactory method will be used based on whether page is being edited
import { componentFactory, editingComponentFactory } from 'temp/componentFactory';
import { sitemapFetcher } from 'lib/sitemap-fetcher';
import { getSitesPaths } from 'lib/multisite/sites';
import Site from 'lib/type/Site';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SMALayout from 'src/SMALayout';
import { SMA_SITE } from '../../../lib/constant';
// import NotFound from 'src/NotFound';
import { useRouter } from 'next/router';
import { getBucketItemDetails } from 'src/GraphQL/queries/bucketItem.query';

const SitecorePage = ({ notFound, componentProps, layoutData }: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  const isEditing = layoutData.sitecore.context.pageEditing;
  const isComponentRendering =
    layoutData.sitecore.context.renderingType === RenderingType.Component;

  const router = useRouter();
  if (notFound || !layoutData.sitecore.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    router.push('/404');
  }

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={isEditing ? editingComponentFactory : componentFactory}
        layoutData={layoutData}
      >
        {/*
          Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
          If you are using Experience Editor only, this logic can be removed, Layout can be left.
        */}
        {isComponentRendering ? (
          <EditingComponentPlaceholder rendering={layoutData?.sitecore?.route as RouteData} />
        ) : (
          <ErrorBoundary>
            {layoutData?.sitecore?.context?.site?.name === SMA_SITE ? (
              <SMALayout layoutData={layoutData} />
            ) : (
              <Layout layoutData={layoutData} />
            )}
          </ErrorBoundary>
        )}
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration
  const paths: StaticPath[] = [];
  let fallback: boolean | 'blocking' = 'blocking';

  if (process.env.NODE_ENV !== 'development' && !process.env.DISABLE_SSG_FETCH) {
    //if (process.env.NODE_ENV === 'development' && !process.env.DISABLE_SSG_FETCH) {
    try {
      // Note: Next.js runs export in production mode
      //paths = await sitemapFetcher.fetch(context);
      //sites data fetch ->>>
      const sites = (await getSitesPaths()) as unknown as Site[];
      let pages = await sitemapFetcher.fetch(sites, context);

      pages = pages.filter((page) => !page.params.path.includes('siteselectionsuccess'));

      const paths = pages.map((page) => ({
        params: { site: page.params.site, path: page.params.path },
        locale: page.locale,
      }));

      return {
        paths,
        fallback: 'blocking',
      };
    } catch (_error) {}
    fallback = 'blocking';
  }

  return {
    paths,
    fallback,
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context: any) => {
  if (
    context &&
    context.params &&
    context.params?.path &&
    context.params?.path[0] === 'find-trials' &&
    context.params?.path[1] === 'Study'
  ) {
    const pathArr = context.params?.path;
    const nctId = pathArr[pathArr.length - 1];
    const resData = await getBucketItemDetails(nctId, context?.locale);
    const fullPath = resData?.search?.ProtocolFolderItem[0]?.path;
    if (fullPath && typeof fullPath === 'string') {
      const graphqlURL = fullPath.substring(fullPath.indexOf('/find-trials'));
      if (pathArr.length > 3) {
        //case where full path is trying to access
        context['params']['path'] = ['404'];
      } else {
        context['params']['path'] = graphqlURL.split('/');
      }
    }
  }
  //TODO: 404 for full path
  const props = await sitecorePagePropsFactory.create(context);

  // Check if we have a redirect (e.g. custom error page)
  if (props.redirect) {
    return {
      redirect: props.redirect,
    };
  }

  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 5 seconds
    revalidate: 3, // In seconds
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
