import React, { Component } from 'react';
import { withRouter, NextRouter } from 'next/router';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  router: NextRouter;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    // Use the Next.js router to navigate to the error page
    this.props.router.push('/errorpage');

    // Set the error state
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.hasError as boolean}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
